<template>
    <div id="bg">
        <div class="search">
            <div class="search-box">
                <span class="search-box-title">用户名：</span>
                <el-input v-model="search.username" class="width_200" placeholder="请输入用户名" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">活动：</span>
                <el-select v-model="search.type" class="width_120" filterable placeholder="全部" size="medium" clearable>
                  <el-option key="" label="全部" value=""></el-option>
                  <el-option v-for="item in types" :key="item" :label="item.title" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="search-box">
                <span class="search-box-title">时间：</span>
                <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="YYYY-MM-DD"></el-date-picker>
            </div>
            <div class="search-box">
                <el-button type="primary" size="medium" @click="getList()">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
            <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="活动类型" align="center" :show-overflow-tooltip="true">
            <template #default="scope">
                <span>{{getTypeTitle(scope.row.type)}}</span>
            </template>
            </el-table-column>
            <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="other" label="兑换的其他物品" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="comment" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="event_price" label="活动金额" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="gift_price" label="兑换金额/数量" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="addtime" label="兑换时间" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span>{{timeToDate(scope.row.addtime)}}</span>
              </template>
            </el-table-column>
        </el-table>
        <page :pager="pager" @query="getList()" />
    </div>
  </template>
  
  <script>
  import { getList } from "@/api/event/exchange_log";
  import page from "@/components/page";
  export default {
    name: "eventExchangeLog",
    components: {
        page,
    },
    data() {
      return {
        search: {
        //搜索
        event_id: "",
        username: "",
        type: "",
        searchDate: [], //时间
      },
        types:[
          {title:"首冲福利",value:1},
          {title:"流水福利",value:2},
          {title:"限时充值",value:3},
          {title:"失利转运",value:4},
          {title:"新手福利",value:5},
          {value:6,title:"现金红包雨"},
          {value:7,title:"幸运大转盘"},
        ],
        pager: { total: 0, page: 0, rows: 10 },
        tableData: [], //数据
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      timeToDate(time){
        if (time == 0){
          return "-"
        }
        return this.$common.timeToDate(time)
      },
      getTypeTitle(_type){
        for(var i in this.types){
          if (this.types[i].value == _type ){
            return this.types[i].title
          }
        }
        return "";
      },
      //获取活动列表
      getList() {
        getList([
            { key: "pageid", val: this.pager.page },
            { key: "pcount", val: this.pager.rows },
            { key: "username", val: this.search.username },
            // { key: "event_id", val: this.search.event_id },
            { key: "type", val: this.search.type },
            { key: "btime", val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[0] : "" },
            { key: "etime",val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[1] : "" },
        ])
          .then((res) => {
            if (res.code == 0) {
              if (this.pager.page == 0){
                this.pager.total = res.data.total;
              }
                this.tableData = res.data.list;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>

  </style>